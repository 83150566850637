export const days = {
  Mo: 'Monday',
  Tu: 'Tuesday',
  We: 'Wednesday',
  Th: 'Thursday',
  Fr: 'Friday',
  Sa: 'Saturday',
  Su: 'Sunday',
}

export const availabilities = {
  onsite: {
    delivery: 'onsite',
    title: 'On location',
    icon: 'home_health.svg',
    alt: 'Icon indicating a location for the service (building)',
  },
  home: {
    delivery: 'home',
    title: 'Home delivery',
    icon: 'local_shipping.svg',
    alt: 'Icon indicating a home delivery service (truck)',
  },
  virtual: {
    delivery: 'virtual',
    title: 'Virtual',
    icon: 'headset_mic.svg',
    alt: 'Icon indicating an online call',
  }
}

export const roles = ['superuser','provider_admin', 'user']
export const adminRoles = ['superuser','provider_admin']

export const portalRoutes = [
  'home',
  'testing',
  'analytics',
  'bookings',
  'bio-age',
  'plan',
  'lab-results',
  'messages',
  'account',
  'users',
  'library',
]

export const portalModuleTitles = {
  'home': 'Home',
  'testing': 'Testing & Diagnostics',
  'search-results-procedure': 'Select a service',
  'analytics': 'Analytics',
  'plan': 'Plan',
  'bookings': 'Bookings',
  'bio-age': 'Bio Age',
  'lab-results': 'Lab results',
  'messages': 'Messages',
  'account': 'My Account',
  'book': 'Finalise booking',
  'book-thank-you': 'Booking confirmed',
  'portal-book': 'Finalise booking',
  'portal-book-thank-you': 'Booking confirmed',
  'users': 'Users',
  'library': 'Library',
}

export const filteTypes = {
  pdf: 'file-pdf.svg',
  image: 'file-image.svg',
  audio: 'file-audio.svg',
  doc: 'file-doc.svg',
  spreadsheet: 'file-spreadsheet.svg',
  compressed: 'file-compressed.svg',
  general: 'file-general.svg',
  invalid: 'file-invalid.svg',
}

export const allowedImageTypes = [
  'image/avif',
  'image/bmp',
  'image/heic',
  'image/heic-sequence',
  'image/heif',
  'image/heif-sequence',
  'image/tiff',
  'image/png',
  'image/jpeg',
  'image/webp',
]

export const mimeTypes = {
  'invalid': 'invalid',
  'application/pdf': 'pdf',
  'image/avif': 'image',
  'image/bmp': 'image',
  'image/heic': 'image',
  'image/heic-sequence': 'image',
  'image/heif': 'image',
  'image/heif-sequence': 'image',
  'image/tiff': 'image',
  'image/png': 'image',
  'image/jpeg': 'image',
  'image/webp': 'image',
  'audio/mpeg3': 'audio',
  'audio/x-mpeg-3': 'audio',
  'audio/wav': 'audio',
  'audio/x-wav': 'audio',
  'audio/ogg': 'audio',
  'audio/webm': 'audio',
  'audio/3gpp': 'audio',
  'audio/3gpp2': 'audio',
  'audio/aac': 'audio',
  'audio/mpeg': 'audio',
  'application/x-bzip': 'compressed',
  'application/x-bzip2': 'compressed',
  'application/gzip': 'compressed',
  'application/vnd.rar': 'compressed',
  'application/x-tar': 'compressed',
  'application/zip': 'compressed',
  'application/x-zip-compressed': 'compressed',
  'application/x-7z-compressed': 'compressed',
  'text/csv': 'spreadsheet',
  'application/excel': 'spreadsheet',
  'application/x-excel': 'spreadsheet',
  'application/x-msexcel': 'spreadsheet',
  'application/vnd.ms-excel': 'spreadsheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'spreadsheet',
  'text/plain': 'doc',
  'application/doc': 'doc',
  'application/ms-doc': 'doc',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
  'application/rtf': 'doc',
}

export const fileUploadStates = {
  'uploaded': 'importing',
  'extracted': 'importing',
  'analyzed': 'importing',
  'mapped': 'prepared',
  'success': 'imported',
  'protected': 'failed',
  'error': 'failed',
}